import React, { useEffect, useMemo } from 'react'
import { useGetModelsQuery } from '../services/api/api-model'
import CarouselModel from './CarouselModel'
import { useAppSelector } from '../services/store/store'
import { getCloseIcon } from '../utils/icon'
import { Button } from 'antd'
import { trackEvent } from '../utils/tracking'
import useCustomTranslation from '../shared_utils/translation'
import { ApiCustomError } from '../types/api-types'

interface ModalSwitchModelProps {
    closeModal(): void
}

export default function ModalSwitchModel(props: ModalSwitchModelProps) {
    const { t } = useCustomTranslation()

    const { closeModal } = props

    const storeConfig = useAppSelector((state) => state.config)
    const hasMultisize = useAppSelector((state) => state.settings.hasMultisize)

    const { data: modelResponse, error: errorModel } = useGetModelsQuery(storeConfig.queryParams)

    const { models, look, itemType } = useMemo(() => {
        // ---- Recuperation de l'item type ----
        let configItemType = undefined
        if (storeConfig.queryParams && storeConfig.queryParams.focus) {
            configItemType = storeConfig.queryParams.focus.toLowerCase()
        } else {
            for (const key in storeConfig.queryParams) {
                const match = key.match(/^([a-z]+)_garment_id$/)
                if (match) {
                    configItemType = match[1]
                    break
                }
            }
        }

        // ---- Recuperation des models et du look ----
        let dataModels = undefined,
            dataLook = undefined
        if (modelResponse && storeConfig.config) {
            dataModels = modelResponse.items
            dataLook = modelResponse.look

            // ---- We fetch the value of nb_models and slice if we the response is bigger than the restriction ----
            const nbModels = parseInt(storeConfig.config['nb_models']) || 0
            if (nbModels > 0 && modelResponse.items.length > nbModels) {
                dataModels = modelResponse.items.slice(0, nbModels)
            }
        }

        return { models: dataModels, look: dataLook, itemType: configItemType }
    }, [modelResponse, storeConfig])

    useEffect(() => {
        if (look && itemType && storeConfig.apiConfig && hasMultisize !== undefined) {
            trackEvent('Open Modal', [look, { item_type: itemType.toUpperCase() }], 'Main')
        }
    }, [look, itemType, storeConfig.apiConfig, hasMultisize])

    useEffect(() => {
        // ---- Pour envoyer un close modal avec data, on se base sur l'unmount ----
        return () => {
            if (look && itemType) {
                trackEvent('Close Modal', [look, { item_type: itemType.toUpperCase() }], 'Main')
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div
            className='modal--container'
            style={{
                ...(errorModel ? { width: '50vw', height: '50vh' } : null),
            }}
        >
            <div className='modal--header'>
                <h2 className='modal--title'>
                    {t('title')} {models ? `(${models.length})` : ''}
                </h2>
                {
                    <Button
                        className='modal--close'
                        onClick={closeModal}
                        icon={getCloseIcon()}
                        type='link'
                    />
                }
            </div>

            <div className='modal--content'>
                {errorModel && (
                    <div style={{ textAlign: 'center' }}>
                        <div style={{ fontWeight: 'bold' }}>{t('error')}</div>
                        <div>{(errorModel as ApiCustomError)?.data?.message}</div>
                    </div>
                )}
                {models && look && itemType && storeConfig && (
                    <CarouselModel
                        models={models}
                        look={look}
                        itemType={itemType}
                        nbMaxCards={storeConfig.apiConfig?.sm_nb_max_cards}
                        enableMoreModels={storeConfig.apiConfig?.enable_more_models}
                    />
                )}
            </div>
        </div>
    )
}
